// src/context/ModeContext.js
import React, { createContext, useState, useContext } from 'react';

// Create a context for theme mode
const ModeContext = createContext();

// A custom hook to use the ModeContext
export const useMode = () => {
    return useContext(ModeContext);
};

// ModeProvider component to provide the current theme mode and a toggle function
export const ModeProvider = ({ children }) => {
    const [mode, setMode] = useState(localStorage.getItem('theme') || 'light');

    const toggleMode = () => {
        const newMode = mode === 'light' ? 'dark' : 'light';
        setMode(newMode);
        localStorage.setItem('theme', newMode); // Save to local storage for persistence
    };

    return (
        <ModeContext.Provider value={{ mode, toggleMode }}>
            {children}
        </ModeContext.Provider>
    );
};
