import BaseLayout from "../../components/BaseLayout";
import React from "react";
import {Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useSettings} from "../../context/SettingsContext";

const Settings = () => {
    const {setEditDialog, settings} = useSettings()


    return <BaseLayout>
        <Stack spacing={1} marginTop={3} >
            <Button variant='contained' sx={{width: 'fit-content'}} onClick={() => setEditDialog(true)}>Szerkesztés</Button>
        </Stack>

        <Stack spacing={1} marginTop={1}>
            <Typography variant='body1' fontWeight='strong'>Képek tárolása ennyi napig: {settings?.keep_images_days}</Typography>
            <Typography variant='body1' fontWeight='strong'>Képek frissítése ennyi percenként: {settings?.image_fetch_interval_minutes}</Typography>
            <Typography variant='body1' fontWeight='strong'>Képek sárgítása ennyi percenként: {settings?.image_fetch_interval_warning_minutes}</Typography>
            <Typography variant='body1' fontWeight='strong'>Képek pirosítása ennyi percenként: {settings?.image_fetch_interval_critical_minutes}</Typography>
        </Stack>

    </BaseLayout>
}

export default Settings;