import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingForm";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomTextField from "../../components/formValidation/CustomTextField";
import {useState} from "react";
import {validateNumber, validateRequired} from "../../components/formValidation/validators";

const SettingsData = ({reference, obj}) => {
    const [data, setData] = useState({
        keep_images_days: obj?.keep_images_days || 0,
        image_fetch_interval_minutes: obj?.image_fetch_interval_minutes || 5,
        image_fetch_interval_warning_minutes: obj?.image_fetch_interval_warning_minutes || 30,
        image_fetch_interval_critical_minutes: obj?.image_fetch_interval_critical_minutes || 60,
    })

    const [errors, setErrors] = useState({
        keep_images_days: false,
        image_fetch_interval_minutes: false,
        image_fetch_interval_warning_minutes: false,
        image_fetch_interval_critical_minutes: false,
    })

    const validators = {
        keep_images_days: [validateRequired, validateNumber],
        image_fetch_interval_minutes: [validateRequired, validateNumber],
        image_fetch_interval_warning_minutes: [validateRequired, validateNumber],
        image_fetch_interval_critical_minutes: [validateRequired, validateNumber],
    }

    return <ErrorHandlingForm reference={reference} errors={errors} data={data} setData={setData} setErrors={setErrors} validators={validators}>
        <Stack spacing={2}>
            <Typography variant='caption' color='primary.main'>Képek tásrolása ennyi napig*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='keep_images_days'
            />
            <Typography variant='caption' color='primary.main'>Képek frissítése ennyi percenként*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='image_fetch_interval_minutes'
            />
            <Typography variant='caption' color='primary.main'>Képek sárgítása ennyi percenként*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='image_fetch_interval_warning_minutes'
            />
            <Typography variant='caption' color='primary.main'>Képek pirosítása ennyi percenként*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='image_fetch_interval_critical_minutes'
            />
        </Stack>
    </ErrorHandlingForm>
}

export default SettingsData