// BaseLayout.js
import React from 'react';
import MyFooter from './Footer';
import MyAppBar from "./MyAppBar";
import {Paper} from "@mui/material";

const BaseLayout = ({ children }) => {
    return (
        <div>
            <MyAppBar />
            <Paper style={{ minHeight: 'calc(100vh - 120px)', padding: '20px', paddingTop: '128px' }}>
                {children}
            </Paper>
            <MyFooter />
        </div>
    );
};

export default BaseLayout;
