// Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import packageJson from '../../package.json';

const MyFooter = () => {
    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: '#333',
                color: '#fff',
                padding: '20px 0',
                textAlign: 'center',
                // position: 'fixed',
                width: '100%',
                // bottom: 0,
            }}
        >
            <Typography variant="body2">
                Fulmer camera | v {packageJson.version}
            </Typography>
        </Box>
    );
};

export default MyFooter;
