import {Backdrop, Box, CircularProgress, Stack, useTheme} from "@mui/material";

const Loading = ({isLoading}) => {
    const t = useTheme()
    return <Backdrop
        sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={isLoading}
    >
        <Box style={{width: '100%', }} >
            <Stack spacing={2} justifyContent='center' alignItems='center'>
                {/*<img src={dinoLogo} className='loading-logo' alt=""/>*/}
                <CircularProgress style={{color: t.palette.primary[700]}} />
            </Stack>
        </Box>
    </Backdrop>
}

export default Loading