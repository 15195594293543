import {createContext, useState, useCallback, useContext} from "react";
import jwt_decode from "jwt-decode";
import {useHistory} from "react-router-dom";
import ConfigContext from "./ConfigContext";
import {useSnackbar} from "notistack";
import axios from "axios";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {
    const {baseUrl} = useContext(ConfigContext)
    const [authTokens, setAuthTokens] = useState(() => localStorage.getItem("authTokens") ? JSON.parse(localStorage.getItem("authTokens")) : null);
    const [user, setUser] = useState(() => localStorage.getItem("authTokens") ? jwt_decode(localStorage.getItem("authTokens")) : null);
    const [permissions, setPermissions] = useState(() => localStorage.getItem("permissions") ? localStorage.getItem("permissions") : [])
    const history = useHistory();
    const snackbar = useSnackbar()

    const api = axios.create({
        baseURL: baseUrl,
        headers: {"Content-Type": "application/json"}
    })

    const setAuthStorage = (data) => {
        setAuthTokens(data);
        const u = jwt_decode(data.access);
        setUser(u);
        setPermissions(u.permissions);
        localStorage.setItem("authTokens", JSON.stringify(data));
        localStorage.setItem("permissions", u?.permissions);
    }

    const loginUser = (data) => {
        api.post('api/token/', JSON.stringify(data))
            .then(r => {
                setAuthStorage(r?.data)
                history.push("/cameras")
            })
            .catch(e => {
                const status = e?.response?.status
                if (status === 401) snackbar.enqueueSnackbar('Hiba, helytelen email vagy jelszó!', {variant: 'error'})
                else snackbar.enqueueSnackbar('Hiba a bejelentkezésnél, próbálja újra később!', {variant: 'error'})
                console.log(e)
            })
    }


    const logoutUser = useCallback(() => {
        setAuthTokens(null)
        setUser(null)
        setPermissions(null)
        localStorage.removeItem("authTokens")
        localStorage.removeItem("permissions")
    }, []);

    const updateToken = useCallback(() => {
        return api.post('api/token/refresh/', JSON.stringify({refresh: authTokens?.refresh}))
            .then(r => {
                const data = r?.data
                setAuthStorage(data)
                return data.access
            })
            .catch(e => {
                logoutUser()
                throw e
            })
    }, [authTokens?.refresh, logoutUser])

    const forgotPassword = (data) => {
        api.post('password/send_link/', JSON.stringify(data))
            .then(r => {
                snackbar.enqueueSnackbar('Email kiküldve!', {variant: 'success'})
                history.push("/")
            })
            .catch(e => {
                snackbar.enqueueSnackbar('Hiba történt!', {variant: 'error'})
                console.log(e)
            })
    }

    const newPassword = (data) => {
        api.post('password/change_password/', JSON.stringify(data))
            .then(r => {
                snackbar.enqueueSnackbar('Sikeresen megváltoztatva!', {variant: 'success'})
                history.push("/login")
            })
            .catch(e => {
                snackbar.enqueueSnackbar('Hiba történt!', {variant: 'error'})
                console.log(e)
            })
    }

    const contextData = {
        user: user,
        authTokens: authTokens,
        loginUser: loginUser,
        logoutUser: logoutUser,
        permissions: permissions,
        forgotPassword: forgotPassword,
        newPassword: newPassword,
        updateToken: updateToken
    };

    return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};