import { createTheme } from "@mui/material/styles";
import { huHU } from "@mui/material/locale";

const darkTheme = createTheme(
    {
        name: 'my-dark-theme',
        palette: {
            mode: 'dark', // Switches to dark mode
            primary: {
                main: '#5F7885', // Light blue for primary color in dark mode
            },
            secondary: {
                main: '#f48fb1', // A secondary color, soft pink for dark mode
            },
            background: {
                default: '#121212', // Dark background for the app
                paper: '#1d1d1d', // Dark paper background for components
            },
            text: {
                primary: '#ffffff', // Light text on dark background
                secondary: '#b0bec5', // Slightly muted secondary text color
            },
        },
        typography: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Default font
            h1: {
                fontWeight: 700, // Bolder headings
            },
            h2: {
                fontWeight: 700,
            },
        },
        // Optionally you can customize other parts of the theme, such as spacing, breakpoints, etc.
    },
    huHU // Applying Hungarian locale
);

export default darkTheme;
