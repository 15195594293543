import React, {useContext, useState} from 'react';
import {Card, CardContent, CardMedia, Typography, Box, Link, Dialog, DialogContent, IconButton} from '@mui/material';
import {Link as RouterLink} from "react-router-dom";
import {repairUrl} from "../utils/misc";
import ConfigContext from "../context/ConfigContext";
import TurnedInRoundedIcon from '@mui/icons-material/TurnedInRounded';
import TurnedInNotRoundedIcon from '@mui/icons-material/TurnedInNotRounded';

const renderExtraData = (data) => {
    // Check if data is an object or array and render it recursively
    if (Array.isArray(data)) {
        return (
            <Box sx={{marginLeft: 2}}>
                {data.map((item, index) => (
                    <Box key={index} sx={{marginBottom: 1}}>
                        {renderExtraData(item)} {/* Recursively render nested items */}
                    </Box>
                ))}
            </Box>
        );
    }

    if (typeof data === 'object' && data !== null) {
        return (
            <Box sx={{marginLeft: 2}}>
                {Object.entries(data).map(([key, value]) => (
                    <Box key={key} sx={{marginBottom: 1}}>
                        <Typography variant="body2" color="textSecondary">
                            <strong>{key}:</strong> {renderExtraData(value)}
                        </Typography>
                    </Box>
                ))}
            </Box>
        );
    }

    // For primitive values (string, number, etc.)
    return <span>{data}</span>;
};


const Entry = ({ entryData, toggleSaved }) => {
    const { baseUrl } = useContext(ConfigContext);
    const [openImageDialog, setOpenImageDialog] = useState(false);

    const handleImageClick = () => {
        setOpenImageDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenImageDialog(false);
    };

    if (!entryData) {
        return <Typography>Nincs elérhető adat</Typography>;
    }

    return (
        <>
            <Card>
                {entryData.image && (
                    <CardMedia
                        component="img"
                        height="100%"
                        image={repairUrl(entryData.image, baseUrl)}
                        alt="Entry Image"
                        onClick={handleImageClick}
                        sx={{ cursor: 'pointer' }}
                    />
                )}
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Link
                            component={RouterLink}
                            to={`/cameras/${entryData.camera?.id}`}
                            variant="h5"
                            color="primary"
                            // sx={{ marginTop: 2, display: 'inline-block' }}
                        >
                            {entryData.camera?.name}
                        </Link>
                        <IconButton
                            onClick={() => toggleSaved(entryData?.id)}
                            color={entryData.saved ? 'warning' : 'default'}
                            aria-label="toggle saved"
                        >
                            {entryData.saved ? <TurnedInRoundedIcon /> : <TurnedInNotRoundedIcon />}
                        </IconButton>
                    </Box>
                    <Typography variant="body2" color="textSecondary">
                        Létrehozva ekkor: {new Date(entryData.created_at).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Frissítve ekkor: {new Date(entryData.updated_at).toLocaleString()}
                    </Typography>
                    {entryData.extra_data && (
                        <Box sx={{ marginTop: 2 }}>
                            <Typography variant="body2" color="textSecondary">
                                <strong>Extra adatok:</strong>
                            </Typography>
                            {renderExtraData(entryData.extra_data)} {/* Recursively render extra_data */}
                        </Box>
                    )}
                </CardContent>
            </Card>

            {/* Dialog for fullscreen image */}
            {entryData.image && (
                <Dialog open={openImageDialog} onClose={handleCloseDialog} maxWidth="lg">
                    <DialogContent sx={{ padding: 0 }}>
                        <img
                            src={repairUrl(entryData.image, baseUrl)}
                            alt="Entry Fullscreen"
                            style={{ width: '80vw', height: 'auto' }}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default Entry;

