import BaseLayout from "../../components/BaseLayout";
import {useCallback, useEffect, useState} from "react";
import Loading from "../../components/Loading";
import useCallDataApi from "../../hooks/data";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import Entry from "../../components/Entry";
import Typography from "@mui/material/Typography";
import {debounce} from "lodash";
import {formatDate} from "../../utils/misc";

const Entries = () => {
    const today = new Date();
    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        created_at_gte: formatDate(new Date(Date.now() - 10 * 24 * 60 * 60 * 1000)),
        created_at_lte: formatDate(new Date()),
    });
    const { getData: fetchEntries } = useCallDataApi('entries');

    const toggleSaved = (entryId) => fetchEntries(`${entryId}/toggle_save`).then(e => {
        const index = entries.findIndex(ent => ent.id === e.id);
        if (index !== -1) {
            const updatedEntries = [...entries]
            updatedEntries[index] = e
            setEntries(updatedEntries)
        }
    })

    const handleFilterChange = (e) => {
        const { name, type, checked, value } = e.target;
        const filterValue = type === 'checkbox' ? checked : value;
        setFilters((prev) => ({ ...prev, [name]: filterValue }));
    };

    const handleOrderingChange = (event) => {
        const { value } = event.target;
        // Update the API request to include the ordering parameter
        setFilters((prev) => ({ ...prev, ordering: value }))
    }

    const applyFilters = useCallback(() => {
        setLoading(true);
        fetchEntries(null, filters)
            .then((data) => setEntries(data))
            .finally(() => setLoading(false));
    }, [filters]);

    const debouncedApplyFilters = useCallback(debounce(applyFilters, 500), [applyFilters]);

    useEffect(() => {
        debouncedApplyFilters();

        // Cleanup to cancel pending debounced calls when the component unmounts
        return () => {
            debouncedApplyFilters.cancel();
        };
    }, [debouncedApplyFilters]);

    return (
        <BaseLayout>
            {/*<Typography variant='h3'>*/}
            {/*    Entries*/}
            {/*</Typography>*/}
            <Grid container spacing={2} sx={{ padding: 0, marginTop: 2 }}>
                {/* Filter Inputs */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="camera_name"
                        label="Camera Name"
                        variant="outlined"
                        fullWidth
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="camera_camera_id"
                        label="Kamera ID"
                        variant="outlined"
                        fullWidth
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="created_at_gte"
                        label="Létrehozás dátuma nagyobb"
                        type="date"
                        variant="outlined"
                        fullWidth
                        value={filters?.created_at_gte}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="created_at_lte"
                        label="Létrehozás dátuma kisebb"
                        type="date"
                        variant="outlined"
                        fullWidth
                        value={filters?.created_at_lte}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleFilterChange}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="saved"
                                color="primary"
                                onChange={handleFilterChange}
                            />
                        }
                        label="Mentett?"
                    />
                </Grid>

                {/* Ordering Selector */}
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id="ordering-label">Rendezés</InputLabel>
                        <Select
                            labelId="ordering-label"
                            name="ordering"
                            defaultValue=""
                            onChange={handleOrderingChange}
                        >
                            <MenuItem value="created_at">Létrehozás dátuma ⬆️</MenuItem>
                            <MenuItem value="-created_at">Létrehozás dátuma ⬇️</MenuItem>
                            <MenuItem value="camera__camera_id">Kamera ID ⬆️</MenuItem>
                            <MenuItem value="-camera__camera_id">Kamera ID ⬇️</MenuItem>
                            <MenuItem value="camera__name">Kamera név ⬆️</MenuItem>
                            <MenuItem value="-camera__name">Kamera név ⬇️</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {/* Entries Grid */}
            <Grid container spacing={3} sx={{padding: 0, marginTop: 2}}>
                {entries.map((entry) => (
                    <Grid item xs={12} sm={6} md={4} key={entry.id}>
                        <Entry entryData={entry} toggleSaved={toggleSaved}/>
                    </Grid>
                ))}
            </Grid>

            <Loading isLoading={loading} />
        </BaseLayout>
    );
};
export default Entries