const repairUrl = (url, baseUrl) => {
    // Ensure baseUrl doesn't end with a slash, and url doesn't start with http
    if (!url.startsWith('http')) {
        return `${baseUrl.replace(/\/$/, '')}/${url.replace(/^\/+/, '')}`;
    }
    return url;
};

const ensureTrailingSlash = url => {
    if (url) {
        const strUrl = url?.toString()
        return strUrl?.endsWith('/') ? strUrl : strUrl + '/';
    }
    return url
}

const formatDate = (date) => date.toISOString().split("T")[0]; // Format to YYYY-MM-DD


export {repairUrl, ensureTrailingSlash, formatDate}
