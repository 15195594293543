import React, {createContext, useContext, useState, useEffect, useRef} from 'react';
import useCallDataApi from '../hooks/data';
import AuthContext from "./AuthContext";
import Loading from "../components/Loading";
import {useSnackbar} from "notistack";
import DetailDialog from "../DetailDialog";
import {Button, Stack} from "@mui/material";
import SettingsData from "../pages/Settings/SettingsData"; // Import your data-fetching hook

// Create the SettingsContext
const SettingsContext = createContext();

// SettingsProvider component to provide context value
export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState({});
    const [editDialog, setEditDialog] = useState(false)
    const [loading, setLoading] = useState(false);
    const { getData, postData } = useCallDataApi('settings'); // Use your custom hook for API calls
    const { user } = useContext(AuthContext)
    const {enqueueSnackbar} = useSnackbar()
    const reference = useRef(null)

    // Fetch the settings when the provider is mounted and user is present
    useEffect(() => {
        if (!user) return; // Don't fetch settings if the user is not present (not authenticated)

        setLoading(true);
        getData('get_settings')
            .then((s) => {
                setSettings(s); // Update the state with fetched settings
            })
            .finally(() => setLoading(false));
    }, []); // Re-fetch when the user changes (i.e., user is authenticated)

    return (
        <SettingsContext.Provider value={{ settings, loading, setEditDialog }}>
            {children}
            <DetailDialog open={editDialog} handleClose={() => setEditDialog(false)}
                          title='Edit Settings'
                          actions={<Stack direction='row' spacing={2}>
                              <Button variant='outlined' onClick={() => setEditDialog(false)}>Bezárás</Button>
                              <Button variant='contained' onClick={() => {
                                  const data = reference.current.getData()
                                  if (data) {
                                      setLoading(true)
                                      postData('set_settings', data).then(s => {
                                          setSettings(s)
                                          setEditDialog(false)
                                      }).finally(() => setLoading(false))
                                  } else {
                                      enqueueSnackbar('Hiba!', {variant: 'error'})
                                  }                              }}>Mentés</Button>
                          </Stack>}>
                <SettingsData obj={settings} reference={reference}/>
            </DetailDialog>
            <Loading isLoading={loading}/>
        </SettingsContext.Provider>
    );
};

// Custom hook to use the SettingsContext
export const useSettings = () => {
    const context = useContext(SettingsContext);
    if (!context) {
        throw new Error('useSettings must be used within a SettingsProvider');
    }
    return context;
};
