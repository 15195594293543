import React, {useContext, useEffect, useRef, useState} from 'react';
import {Container, Card, CardContent, Typography, Button, Box, Grid, CardMedia} from '@mui/material';
import BaseLayout from "../../components/BaseLayout";
import useCallDataApi from "../../hooks/data";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import Loading from "../../components/Loading";
import Entry from "../../components/Entry";
import RefreshIcon from '@mui/icons-material/Refresh';



const CameraDetail = () => {
    const [camera, setCamera] = useState({});
    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(false);
    const {getData: fetchCameras} = useCallDataApi('cameras')
    const {getData: fetchEntries} = useCallDataApi('entries')
    const params = useParams()

    const toggleSaved = (entryId) => fetchEntries(`${entryId}/toggle_save`).then(e => {
        const index = entries.findIndex(ent => ent.id === e.id);
        if (index !== -1) {
            const updatedEntries = [...entries]
            updatedEntries[index] = e
            setEntries(updatedEntries)
        }
    })

    const getLatestImages = () => {
        setLoading(true);
        fetchCameras(`${params.id}/get_new_images/`).then(data => {
            setEntries(data?.entries)
            setCamera({...camera, last_fetch: data?.last_fetch})
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        setLoading(true);
        Promise.all([
            fetchCameras(params.id).then(data => setCamera(data)),
            fetchCameras(`${params.id}/get_images/`).then(data => setEntries(data))
        ]).finally(() => setLoading(false));
    }, [])

    return (
        <BaseLayout>
            <Container maxWidth="sm">

                {/* Camera Details Section */}
                <Card sx={{ boxShadow: 3, marginBottom: 4 }}>
                    <CardContent>
                        <Typography variant="h4" component="div" gutterBottom>
                            Név: {camera?.name}
                        </Typography>
                        <Typography variant="h4" component="div" gutterBottom>
                            Kamera ID: {camera?.camera_id}
                        </Typography>
                        <Typography variant="body1" color="text.secondary" paragraph>
                            <strong>Telefonszám:</strong> {camera?.phone_number}
                        </Typography>
                        <Typography variant="body1" color="text.secondary" paragraph>
                            <strong>Kamera Típusa:</strong> {camera?.camera_type}
                        </Typography>
                        <Typography variant="body1" color="text.secondary" paragraph>
                            <strong>Utolsó adatbetöltés:</strong> {new Date(camera?.last_fetch).toLocaleString()}
                        </Typography>

                        <Box sx={{ marginTop: 2 }}>
                            {/*<Button variant="outlined" color="primary" fullWidth sx={{ marginBottom: 2 }}>*/}
                            {/*    Edit Camera*/}
                            {/*</Button>*/}
                            {/*<Button variant="contained" color="secondary" fullWidth sx={{ marginBottom: 2 }}>*/}
                            {/*    View Logs*/}
                            {/*</Button>*/}
                            {/*<Button variant="outlined" color="error" fullWidth sx={{ marginBottom: 2 }}>*/}
                            {/*    Delete Camera*/}
                            {/*</Button>*/}
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                onClick={() => getLatestImages()}
                                startIcon={<RefreshIcon />} // Add the refresh icon here
                            >
                                Legutóbbi képek frissítése
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            <Typography variant='h6'>Legutóbbi képek:</Typography>
            <Grid container spacing={3} sx={{ padding: 2 }}>
                {entries.map((entry) => (
                    <Grid item xs={12} sm={6} md={4} key={entry.id}>
                        <Entry entryData={entry} toggleSaved={toggleSaved}/> {/* Use the Entry component */}
                    </Grid>
                ))}
            </Grid>
            <Loading isLoading={loading}/>


        </BaseLayout>
    );
};

export default CameraDetail;
