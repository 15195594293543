import {MenuItem, Stack} from "@mui/material";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingForm";
import {validateMaxLength, validateRequired} from "../../components/formValidation/validators";
import CustomTextField from "../../components/formValidation/CustomTextField";
import CustomSelect from "../../components/formValidation/CustomSelect";

const CameraData = ({reference, obj}) => {

    const [data, setData] = useState({
        name: obj?.name || '',
        camera_id: obj?.camera_id || '',
        phone_number: obj?.phone_number || '',
        camera_type: obj?.camera_type || '',
    })

    const [errors, setErrors] = useState({
        name: false,
        camera_id: false,
        phone_number: false,
        camera_type: false,
    })

    const validators = {
        name: [validateRequired, value => validateMaxLength(255, value)],
        camera_id: [validateRequired, value => validateMaxLength(511, value)],
        phone_number: [validateRequired, value => validateMaxLength(511, value)],
        camera_type: [validateRequired, value => validateMaxLength(255, value)]
    }

    return <ErrorHandlingForm reference={reference} errors={errors} data={data} setData={setData} setErrors={setErrors} validators={validators}>
        <Stack spacing={2}>
            <Typography variant='caption' color='primary.main'>Név*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='name'
            />
            <Typography variant='caption' color='primary.main'>Kamera ID*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='camera_id'
            />
            <Typography variant='caption' color='primary.main'>Telefonszám*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='phone_number'
            />
            <Typography variant='caption' color='primary.main'>Camera típusa*</Typography>
            <CustomSelect
                data={data}
                setData={setData}
                name='camera_type'
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            >
                <MenuItem value='TrailCamera'>TrailCamera</MenuItem>
            </CustomSelect>
        </Stack>
    </ErrorHandlingForm>
}

export default CameraData