import React from 'react';
import Fab from '@mui/material/Fab';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const FloatingActionButton = ({ onClick, color, icon }) => {
    return (
        <Fab
            color={color || 'primary'}
            aria-label="action"
            onClick={onClick}
            sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                zIndex: 1000,
            }}
        >
            {icon || <AddOutlinedIcon />}
        </Fab>
    );
};


export default FloatingActionButton;
