import BaseLayout from "../components/BaseLayout";

const NotFound = () => {
  return (
      <BaseLayout>
        <div style={{textAlign: "center", marginTop: "50px", padding: "20px"}}>
          <h1 style={{fontSize: "4rem", color: "#ffba08"}}>404</h1>
          <h2 style={{fontSize: "2rem", color: "#d97706"}}>
            Oh, ne! Ez az oldal üres!
          </h2>
          <p style={{fontSize: "1.2rem", marginTop: "10px", color: "#6b705c"}}>
            Úgy tűnik, ez az oldal elszállt, vagy soha nem is létezett. Talán a méz nyomai visszavezetnek {" "}
            <a href="/" style={{color: "#d97706", textDecoration: "underline"}}>
              a főoldalra
            </a>
            .
          </p>
        </div>
      </BaseLayout>
  );
};

export default NotFound;
