import {IconButton, useTheme} from '@mui/material';
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";



const ModeSwitcher = ({ mode, handleModeToggle }) => {
    const theme = useTheme()
    return (
        <IconButton
            onClick={handleModeToggle}
            sx={{
                p: 1,
                border: `1px ${theme.palette.text.disabled} solid white`,
            }}
            size="small"
            color="inherit"
        >
            {mode === "dark" ? (
                <LightModeOutlined />
            ) : (
                <DarkModeOutlined />
            )}
        </IconButton>
    );
};

export default ModeSwitcher;
