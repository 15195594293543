import {createTheme} from "@mui/material/styles";
import {huHU} from "@mui/material/locale";


const theme = createTheme({
        name: 'my-theme',
        mode: 'light',
        palette: {
            primary: {
                main: '#5F7885'
            }
        }
    },
    huHU
)

export default theme