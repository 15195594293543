import React, { useContext, useState } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Button,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AuthContext from "../context/AuthContext";
import fulmerLogo from '../assets/fulmer_logo.svg';
import { useMode } from "../context/ModeContext";
import ModeSwitcher from "./ModeSwitcher";
import {useHistory} from "react-router-dom";

function MyAppBar() {
    const { user, logoutUser } = useContext(AuthContext);
    const { mode, toggleMode } = useMode();
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const history = useHistory();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile screens

    // Handle account menu
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logoutUser();
        setAnchorEl(null);
    };

    const handleModeToggle = () => {
        toggleMode();
    };

    // Toggle mobile drawer
    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    // Menu items
    const menuItems = [
        { name: 'Kamerák', url: '/cameras' },
        { name: 'Képek', url: '/entries' },
        { name: 'Beállítások', url: '/settings' },
    ];

    return (
        <AppBar position="fixed" sx={{ width: '100%' }}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* Logo */}
                <img src={fulmerLogo} alt="Logo" style={{ cursor: "pointer", maxHeight: 40 }} onClick={() => history.push('/cameras')} />

                {/* Menu and Account Circle */}
                <div>
                    {isMobile && user ? (
                        <>
                            <IconButton
                                color="inherit"
                                onClick={toggleDrawer(true)}
                                aria-label="open drawer"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Drawer
                                anchor="left"
                                open={drawerOpen}
                                onClose={toggleDrawer(false)}
                            >
                                <List>
                                    {menuItems.map((item) => (
                                        <ListItem
                                            button
                                            key={item.name}
                                            onClick={toggleDrawer(false)}
                                            component="a"
                                            href={item.url}
                                        >
                                            <ListItemText primary={item.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Drawer>
                        </>
                    ) : (
                        <>
                            {user &&
                                menuItems.map((item) => (
                                    <Button key={item.name} href={item.url} color="inherit">
                                        {item.name}
                                    </Button>
                                ))}
                        </>
                    )}

                    {/* Mode Selector */}
                    <ModeSwitcher handleModeToggle={handleModeToggle} mode={mode} />

                    {user ? (
                        <>
                            {/* Account Circle */}
                            <IconButton
                                color="inherit"
                                onClick={handleMenuClick}
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                            >
                                <AccountCircle />
                            </IconButton>

                            {/* Account Menu */}
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseMenu}
                            >
                                <MenuItem onClick={handleLogout}>Kilépés</MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <Button href="/login" color="inherit">
                            Belépés
                        </Button>
                    )}
                </div>
            </Toolbar>
        </AppBar>
    );
}

export default MyAppBar;
