import {MenuItem, Stack} from "@mui/material";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import {
    validateMaxLength,
    validateNumber,
    validateRequired,
    validateTime
} from "../../../components/formValidation/validators";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import CustomSelect from "../../../components/formValidation/CustomSelect";


const TrailCameraData = ({reference, obj}) => {

    const [data, setData] = useState({
        sms_remote: obj?.sms_remote || '0',
        pic_size: obj?.pic_size || '0',
        max_num: obj?.max_num || '',
        daily_report: obj?.daily_report || '',
        mms: obj?.mms || '0',
        smtp: obj?.smtp || '0',
        ftp_s: obj?.ftp_s || '0',
        // image_size_mb: obj?.image_size_mb || '4',
    })

    const [errors, setErrors] = useState({
        sms_remote: false,
        pic_size: false,
        max_num: false,
        daily_report: false,
        mms: false,
        smtp: false,
        ftp_s: false,
        // image_size_mb: false,
    })

    const validators = {
        sms_remote: [validateRequired, value => validateMaxLength(2, value)],
        pic_size: [validateRequired, value => validateMaxLength(2, value)],
        max_num: [validateNumber],
        daily_report: [validateTime],
        mms: [validateRequired, value => validateMaxLength(2, value)],
        smtp: [validateRequired, value => validateMaxLength(2, value)],
        ftp_s: [validateRequired, value => validateMaxLength(2, value)],
        // image_size_mb: [validateRequired, value => validateMaxLength(2, value)],
    }

    return <ErrorHandlingForm reference={reference} errors={errors} data={data} setData={setData} setErrors={setErrors} validators={validators}>
        <Stack spacing={2}>

            <Typography variant='caption' color='primary.main'>Napi riport</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='daily_report'
            />
            <Typography variant='caption' color='primary.main'>SMS vezérlés*</Typography>
            <CustomSelect
                data={data}
                setData={setData}
                name='sms_remote'
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            >
                <MenuItem value='0'>Napi</MenuItem>
                <MenuItem value='1'>Instant</MenuItem>
            </CustomSelect>
            <Typography variant='caption' color='primary.main'>Kép méret*</Typography>
            <CustomSelect
                data={data}
                setData={setData}
                name='pic_size'
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            >
                <MenuItem value='0'>Kicsi</MenuItem>
                <MenuItem value='1'>Közepes</MenuItem>
                <MenuItem value='2'>Nagy</MenuItem>
            </CustomSelect>
            <Typography variant='caption' color='primary.main'>Max szám</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='max_num'
            />
            <Typography variant='caption' color='primary.main'>MMS*</Typography>
            <CustomSelect
                data={data}
                setData={setData}
                name='mms'
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            >
                <MenuItem value='0'>Nem</MenuItem>
                <MenuItem value='1'>Igen</MenuItem>
            </CustomSelect>
            <Typography variant='caption' color='primary.main'>SMTP*</Typography>
            <CustomSelect
                data={data}
                setData={setData}
                name='smtp'
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            >
                <MenuItem value='0'>Nem</MenuItem>
                <MenuItem value='1'>Igen</MenuItem>
            </CustomSelect>
            <Typography variant='caption' color='primary.main'>FTP(S)*</Typography>
            <CustomSelect
                data={data}
                setData={setData}
                name='ftp_s'
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            >
                <MenuItem value='0'>Ftp</MenuItem>
                <MenuItem value='1'>OFF</MenuItem>
                <MenuItem value='2'>Ftps</MenuItem>
            </CustomSelect>
            {/*<Typography variant='caption' color='primary.main'>Image size MB*</Typography>*/}
            {/*<CustomSelect*/}
            {/*    data={data}*/}
            {/*    setData={setData}*/}
            {/*    name='image_size_mb'*/}
            {/*    errors={errors}*/}
            {/*    setErrors={setErrors}*/}
            {/*    validators={validators}*/}
            {/*>*/}
            {/*    <MenuItem value='1'>24 MB</MenuItem>*/}
            {/*    <MenuItem value='2'>12 MB</MenuItem>*/}
            {/*    <MenuItem value='3'>8 MB</MenuItem>*/}
            {/*    <MenuItem value='4'>5 MB</MenuItem>*/}
            {/*</CustomSelect>*/}
        </Stack>
    </ErrorHandlingForm>
}

export default TrailCameraData