// Login.js
import React, {useContext, useState} from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import BaseLayout from "../../components/BaseLayout";
import AuthContext from "../../context/AuthContext";
import {useMode} from "../../context/ModeContext";
import fulmerWhite from "../../assets/fulmer_logo.svg";
import fulmerBlue from "../../assets/fulmer_logo_blue.svg";
import {useHistory} from "react-router-dom";



const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const {loginUser, user} = useContext(AuthContext)
    const {mode} = useMode()
    const history = useHistory()

    if (user) history.push('/')

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would normally handle form submission, e.g., API call
        if (!email || !password) {
            setError('Minden adatot meg kell adni.');
        } else {
            setError('');
            // Example of login logic
            loginUser({email: email, password: password});
            // Reset form or proceed to dashboard after successful login
        }
    };

    return (
        <BaseLayout>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                        maxWidth: 400,
                        padding: 3,
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        boxShadow: 2,
                    }}
                >
                    <img src={mode === 'light' ? fulmerBlue : fulmerWhite} alt="Logo" style={{maxHeight: 60}}/>
                    <Typography variant="h5" gutterBottom align="center">Belépés</Typography>

                    {/* Email Input */}
                    <TextField
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        required
                    />

                    {/* Password Input */}
                    <TextField
                        label="Jelszó"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        required
                    />

                    {/* Error Message */}
                    {error && (
                        <Typography variant="body2" color="error" align="center">
                            {error}
                        </Typography>
                    )}

                    {/* Submit Button */}
                    <Button type="submit" variant="contained" color="primary">
                        Bejelentkezés
                    </Button>
                </Box>
            </Box>
        </BaseLayout>
    );
};

export default Login;
