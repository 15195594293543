import './App.scss';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import fulmerTheme from "./components/themes/Theme";
import darkFulmerTheme from "./components/themes/DarkTheme";
import {ConfigProvider} from "./context/ConfigContext";
import {AuthProvider} from "./context/AuthContext";
import PrivateRoute from "./utils/PrivateRoute";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/auth/Login";
import Entries from "./pages/Entry/Entries";
import Cameras from "./pages/Camera/Cameras";
import CameraDetail from "./pages/Camera/Camera";
import {ModeProvider, useMode} from "./context/ModeContext";
import Settings from "./pages/Settings/Settings";
import NotFound from "./pages/NotFound";
import {SettingsProvider} from "./context/SettingsContext";


const App = () => {
    const { mode } = useMode();

    return (
        <ThemeProvider theme={mode === 'light' ? fulmerTheme : darkFulmerTheme}>
            <ConfigProvider>
                <Router>
                    <AuthProvider>
                        <SettingsProvider>
                            <Switch>
                                {/*<PrivateRoute path="/" component={Dashboard} exact />*/}
                                <PrivateRoute path="/entries" component={Entries} exact />
                                <PrivateRoute path="/cameras" component={Cameras} requiredPermissions={['view_camera']} exact />
                                <PrivateRoute path="/cameras/:id" component={CameraDetail} requiredPermissions={['view_camera']} exact />
                                <PrivateRoute path="/settings" component={Settings} requiredPermissions={['view_settings']} exact />
                                <Route path="/login" component={Login} exact />
                                <Route component={NotFound}/>
                            </Switch>
                        </SettingsProvider>

                    </AuthProvider>
                </Router>
            </ConfigProvider>
        </ThemeProvider>
    );
};

const AppWithMode = () => (
    <ModeProvider>
        <App />
    </ModeProvider>
);

export default AppWithMode;