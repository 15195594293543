import BaseLayout from "../../components/BaseLayout";
import React, {useContext, useEffect, useRef, useState} from "react";
import Loading from "../../components/Loading";
import useCallDataApi from "../../hooks/data";
import {Button, Grid, Stack} from "@mui/material";
import CameraDisplay from "../../components/Camera";
import DetailDialog from "../../DetailDialog";
import CameraData from "./CameraData";
import FloatingActionButton from "../../components/Fab";
import ConfirmationDialog from "../../components/CustomDialog";
import AuthContext from "../../context/AuthContext";
import Typography from "@mui/material/Typography";
import {useSnackbar} from "notistack";
import TrailCameraData from "./CameraSpecificData/TrailCamera";
import {useSettings} from "../../context/SettingsContext";

const Cameras = () => {
    const [cameras, setCameras] = useState([]);
    const [loading, setLoading] = useState(false);
    const {getData: fetchCameras, postData: postCameras, updateData, deleteData} = useCallDataApi('cameras')
    const [editDialog, setEditDialog] = useState(false);
    const [configureDialog, setConfigureDialog] = useState(false);
    const [sendDialog, setSendDialog] = useState(false);
    const [newDialog, setNewDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [current, setCurrent] = useState(null);
    const ref = useRef(null);
    const { settings } = useSettings()
    const intervalTime = (settings?.image_fetch_interval_minutes || 5) * 60 * 1000; // Change 5 to your desired number of minutes
    const {permissions} = useContext(AuthContext)
    const {enqueueSnackbar} = useSnackbar()


    useEffect(() => {
        const fetchData = () => {
            setLoading(true);
            fetchCameras()
                .then(data => setCameras(data))
                .finally(() => setLoading(false));
        };

        fetchData(); // Initial fetch
        const intervalId = setInterval(fetchData, intervalTime); // Fetch every `intervalTime` milliseconds

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, []);

    const cameraTypeComponentMap = {
        TrailCamera: TrailCameraData,
        // Add other camera types here, e.g.,
        // SecurityCamera: SecurityCameraData,
    };

    const CameraDetailsComponent = cameraTypeComponentMap[cameras.find(c => c.id === current)?.camera_type];

    const configureSettings = (camera_id) => fetchCameras(`${camera_id}/configure_settings`).then(() => enqueueSnackbar('Beállítva!', {variant: 'success'}))

    return <BaseLayout>
        {/*<Typography variant='h3'>*/}
        {/*    Cameras*/}
        {/*</Typography>*/}
        {permissions.includes('view_camera') && <Grid container spacing={3} sx={{padding: 0, marginTop: 2}}>
            {cameras.map((camera) => (
                <Grid item xs={12} sm={6} md={4} key={camera.id}>
                    <CameraDisplay
                        data={camera}
                        editSettings={() => {
                            setCurrent(camera.id)
                            setConfigureDialog(true)
                        }} configure={() => {
                            setCurrent(camera.id)
                            setSendDialog(true)
                        }} edit={() => {
                            setCurrent(camera.id)
                            setEditDialog(true)
                        }} destroy={() => {
                            setCurrent(camera.id)
                            setDeleteDialog(true)
                        }}
                    />
                </Grid>
            ))}
        </Grid>}

        <DetailDialog open={editDialog} handleClose={() => setEditDialog(false)}
                      title='Kamera szerkesztése'
                      actions={<Stack direction='row' spacing={2}>
                          <Button variant='outlined' onClick={() => setEditDialog(false)}>Bezárás</Button>
                          <Button variant='contained' onClick={() => {
                              const data = ref.current.getData()
                              if (data) {
                                  updateData(current, data).then(c => {
                                      const index = cameras.findIndex(cam => cam.id === c.id);
                                      if (index !== -1) {
                                          const updatedCameras = [...cameras]
                                          updatedCameras[index] = c
                                          setCameras(updatedCameras)
                                      }
                                  })
                                  setEditDialog(false)
                              } else {
                                  alert('Error!')
                              }
                          }}>Mentés</Button>
                      </Stack>}>
            <CameraData obj={cameras.find(c => c.id === current)} reference={ref}/>
        </DetailDialog>

        <DetailDialog open={newDialog} handleClose={() => setNewDialog(false)}
                      title='Új Kamera'
                      actions={<Stack direction='row' spacing={2}>
                          <Button variant='outlined' onClick={() => setNewDialog(false)}>Bezárás</Button>
                          <Button variant='contained' onClick={() => {
                              const data = ref.current.getData()
                              if (data) {
                                  postCameras('', data).then(c => setCameras([...cameras, c]))
                                  setNewDialog(false)
                              } else {
                                  alert('Hiba!')
                              }
                          }}>Mentés</Button>
                      </Stack>}>
            <CameraData reference={ref}/>
        </DetailDialog>

        <DetailDialog open={configureDialog} handleClose={() => setConfigureDialog(false)}
                      title='Kamera beállítása'
                      actions={<Stack direction='row' spacing={2}>
                          <Button variant='outlined' onClick={() => setConfigureDialog(false)}>Bezárás</Button>
                          <Button variant='contained' onClick={() => {
                              const data = ref.current.getData()
                              if (data) {
                                  updateData(current, data).then(c => {
                                      const index = cameras.findIndex(cam => cam.id === c.id);
                                      if (index !== -1) {
                                          const updatedCameras = [...cameras]
                                          updatedCameras[index] = c
                                          setCameras(updatedCameras)
                                      }
                                  })
                                  setConfigureDialog(false)
                              } else {
                                  alert('Hiba!')
                              }
                          }}>Mentés</Button>
                      </Stack>}>
            {CameraDetailsComponent
                ? <CameraDetailsComponent obj={cameras.find(c => c.id === current)} reference={ref}/>
                : <div>Nincs ilyen típus</div>
            }
        </DetailDialog>

        <ConfirmationDialog
            open={sendDialog}
            onClose={() => setSendDialog(false)}
            onConfirm={() => configureSettings(current)}
            message='Bizotosan ki szeretnéd küldeni a konfigurációt?'
            title='Kamera beállítása'
        />

        <ConfirmationDialog
            open={deleteDialog}
            onClose={() => setDeleteDialog(false)}
            onConfirm={() => deleteData(current).then(() => {
                setCameras(cameras.filter(c => c?.id !== current))
                setDeleteDialog(false)
            })}
            message='Biztosan ki szeretnéd törölni a kamerát?'
            title='Kamera törlése'
        />


        {permissions?.includes('add_camera') && <FloatingActionButton onClick={() => setNewDialog(true)}/>}
        <Loading isLoading={loading}></Loading>
    </BaseLayout>
}

export default Cameras